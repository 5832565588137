<template>
    <v-snackbar
        v-model="active"
        :timeout="timeout"
        color="white"
        location="top"
        :content-class="`toast-parent ${snackbarColor}`"
    >
        <v-icon v-if="type === 0" color="orange">mdi-alert</v-icon>
        <v-icon v-else-if="type === -1" color="red">mdi-check</v-icon>
        <v-icon v-else color="blue">mdi-check</v-icon>

        <span style="color: black"> {{ title }} </span>

        <template v-slot:actions>
            <v-btn
                variant="text"
                @click="close"
                color="black"
                size="x-small"
                class="ben-btn ben-btn--basic"
            >
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script lang="ts">
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator'

export enum ToastType {
  error = -1,
  warning = 0,
  success = 1,
}

@Component
class Toast extends Vue {
  @Prop({ default: -1 }) timeout!: number

  private type: ToastType = ToastType.success
  private title: string | null = null
  private active = false
  private timeoutId: number | undefined
  private callback: (() => void) | null = null

  get snackbarColor (): string {
    switch (this.type) {
      case ToastType.error:
        return 'error'
      case ToastType.warning:
        return 'warning'
      case ToastType.success:
      default:
        return 'success'
    }
  }

  private open (title: string) {
    this.title = title
    this.active = true
  }

  success (title: string, timeout: number, callback: () => void) {
    this.timeoutId = timeout
    this.callback = callback
    this.type = ToastType.success
    this.open(title)
  }

  warning (title: string) {
    this.type = ToastType.warning
    this.open(title)
  }

  error (title: string, timeout: number, callback: () => void) {
    this.timeoutId = timeout
    this.callback = callback
    this.type = ToastType.error
    this.open(title)
  }

  close () {
    this.active = false
    clearTimeout(this.timeoutId)
    if (this.callback) {
      this.callback()
    }
  }
}
export default toNative(Toast)
</script>

<style>
.toast-parent.warning {
    border-top: 5px solid orange !important;
}
.toast-parent.success {
    border-top: 5px solid blue !important;
}
.toast-parent.error {
    border-top: 5px solid red !important;
}
.v-snackbar.v-snackbar--active {
  padding-left: 0;
}
</style>
