import moment from 'moment'

export default abstract class DateUtils {
  static generateUpdatedCreatedString (model: any): string {
    if (model !== null && model.newestAuditEvent !== null) {
      let prefixString = ''
      if (model.newestAuditEvent.auditEventActionCode === 'CREATE') {
        prefixString = 'Created '
      } else if (model.newestAuditEvent.auditEventActionCode === 'UPDATE') {
        prefixString = 'Updated '
      }

      return prefixString + moment(model.newestAuditEvent.date, ['MM/DD/YYYY', 'MM-DD-YYYY', moment.ISO_8601]).fromNow()
    } else {
      return ''
    }
  }

  static checkDateFormat (date: string, format?: string): any {
    format = format || 'MM/DD/YYYY'
    let regex = /^[0-9]{2}[/][0-9]{2}[/][0-9]{4}$/g

    if (format === 'MM/YYYY') {
      regex = /^[0-9]{2}[/][0-9]{4}$/g
    } else if (format === 'YYYY') {
      regex = /^[0-9]{4}$/g
    }

    return regex.test(date) || format
  }

  static getTimestamp (date: string): number {
    return moment.utc(
      date,
      ['MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY/MM/DD', 'YYYY-MM-DD', moment.ISO_8601]
    ).valueOf()
  }

  static dateFormat (date: string, format?: string): string | null {
    if (date === null || date === undefined || date === '') {
      return null
    } else {
      return moment.utc(date, ['MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY/MM/DD', 'YYYY-MM-DD', moment.ISO_8601]).format(format || 'MM/DD/YYYY')
    }
  }

  static dateFormatLocalized (date: string, format?: string): string | null {
    if (date === null || date === undefined || date === '') {
      return null
    } else {
      return moment(date, ['MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY/MM/DD', 'YYYY-MM-DD', moment.ISO_8601]).tz(moment.tz.guess()).format(format || 'MM/DD/YYYY')
    }
  }

  static getTodaysDate (): string {
    return moment.utc(moment.now()).format('MM/DD/YYYY')
  }
}
