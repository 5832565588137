import FilterGroup from '@/models/FilterGroup'
import List from '@/models/List'
import ListHeader from '@/models/ListHeader'
import { Commit } from 'vuex'

export default {
  namespaced: true,
  state () {
    return {
      list: { quickSearch: null, pagination: { page: 1 } } as List,
      totalCount: null as number | null,
      filterGroup: null as FilterGroup | null,
      submittedFilterGroup: null as FilterGroup | null,
      createUpdateModel: null as any,
      createUpdateBulk: null as any[] | null,
      submittedDetailFilterGroup: null as FilterGroup | null,
      loading: false
    }
  },
  actions: {
    resetStoreState ({ commit }: {commit: Commit}) {
      commit('resetStoreState')
    }
  },
  mutations: {
    setList (state: any, list: List) {
      state.list = list
    },
    setQuickSearchFields (state: any, quickSearchFields: string[]) {
      state.list.quickSearchFields = quickSearchFields
    },
    setQuickSearch (state: any, quickSearch: string) {
      state.list.quickSearch = quickSearch
    },
    setTotalItems (state: any, totalItems: number) {
      state.list.totalItems = totalItems
    },
    setListData (state: any, listData: any[]) {
      state.list.listData = listData
    },
    setTotalCount (state: any, value: number | null) {
      state.totalCount = value
    },
    setFilterGroup (state: any, filterGroup: FilterGroup) {
      state.filterGroup = filterGroup
    },
    setSubmittedFilterGroup (state: any, submittedFilterGroup: FilterGroup) {
      state.submittedFilterGroup = submittedFilterGroup
    },
    setSubmittedDetailFilterGroup (state: any, submittedDetailFilterGroup: FilterGroup) {
      state.submittedDetailFilterGroup = submittedDetailFilterGroup
    },
    setCreateUpdateModel (state: any, createUpdateModel: any) {
      state.createUpdateModel = createUpdateModel
    },
    setCreateUpdateBulk (state: any, createUpdateBulk: any[]) {
      state.createUpdateBulk = createUpdateBulk
    },
    setCreateUpdateBulkModel (state: any, { model, index }: {model: any, index: number}) {
      state.createUpdateBulk[index] = model
    },
    setPage (state: any, page: number) {
      state.list.pagination.page = page
    },
    resetSubmittedDetailFilterGroup (state: any) {
      state.submittedDetailFilterGroup = null
    },
    resetStoreState (state: any) {
      state.list = { quickSearch: null, pagination: { page: 1 } }
      state.filterGroup = null
      state.submittedFilterGroup = null
      state.createUpdateModel = null
      state.createUpdateBulk = null
      state.submittedDetailFilterGroup = null
      state.loading = false
      state.totalCount = null
    },
    setHeaders (state: any, headers: ListHeader[]) {
      state.list.headers = headers
    },
    setLoadingState (state: any, loading: boolean) {
      state.loading = loading
    }
  }
}
